<template>
  <v-chip
    :class="cssClass"
    :color="color"
    :text-color="textColor"
    :width="width"
  >
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  name: "GStatusChip",
  props: {
    cssClass: {
      type: String,
      default: () => "ma-2",
    },
    color: {
      type: String,
      default: () => "red",
    },
    textColor: {
      type: String,
      default: () => "white",
    },
    text: {
      type: String,
      default: () => "Unpaid",
    },
    width: {
      type: Number,
      default: () => 200,
    },
  },
};
</script>

<style scoped></style>
