<template>
  <v-card-title style="margin-bottom: 32px">
    <v-sheet class="title-container" color="indigo lighten-1" dark>
      <div class="page-icon">
        <v-icon v-if="icon">{{ icon }}</v-icon>
      </div>
      <div style="flex: 1">
        <h1 class="headline font-weight-light mb-0">{{ title }}</h1>
      </div>
    </v-sheet>
  </v-card-title>
</template>

<script>
export default {
  name: "GPageTitle",
  props: {
    title: {
      type: String,
      default: function () {
        return "";
      },
    },
    icon: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.title-container {
  position: absolute;
  left: 10px;
  top: -5px;
  z-index: 1;
  border-radius: 2px 2px 12px 12px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  .page-icon {
    flex: 0 0 24px;
    padding: 5px 15px;
    font-size: 22px;
  }
  h1 {
    padding: 5px 25px 0 0 !important;
    font-size: 18px !important;
  }
}
</style>
