<template>
  <div id="listPageId">
    <template>
      <slot name="overlay"></slot>
    </template>
    <v-data-table
       v-model="selected"
      :headers="headers"
      :items="data"
      :footer-props="{ 'items-per-page-options': [...limitList] }"
      :options.sync="pagination"
      :server-items-length="total"
      :sort-by="sort"
      :sort-desc="descending"
      :class="elevation"
      :show-select="showSelected"
       @input="selectedItemHandler"
    >
      <template v-slot:top>
        <slot name="top"></slot>
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-for="column in dynamicColumns" v-slot:[`item.${column.name}`]="{ item }">
        {{ column.callback(item, column.name) }}
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.isActive="{ item }">
        <g-switch
          v-if="!item.isDeleted"
          :current-item="item"
          @switchHandler="switchHandler"
        />
        <g-status-chip v-else text="Deleted" color="error" />
      </template>
      <!-- eslint-disable-next-line  -->
      <template v-slot:item.actions="{ item }">
        <row-action-menu-button
          :action-columns="defaultActionButtons"
          :column="item"
          :row="item"
          @actionButtonClick="handleActionButtonClick"
        />
      </template>
      <template v-slot:[`footer.prepend`]></template>
    </v-data-table>
  </div>
</template>

<script>
import RowActionMenuButton from "../../components/RowActionMenuButton";
import { mapState } from "vuex";
import GSwitch from "../../components/list/GSwitch";
import GStatusChip from "../../components/list/GStatusChip";
import {countries} from "country-list-json";
export default {
  name: "GListPage",
  components: {
    GStatusChip,
    GSwitch,
    RowActionMenuButton
  },
  data() {
    return {
      selected: [],
      limitList: [5, 10, 20, 50, 100],
      pagination: {
        itemsPerPage: 20,
      },
      total: 0,
      defaultActionButtons: [
        {
          category: "edit",
          icon: "mdi-pencil",
          color: "",
          text: "Edit",
          clickHandler: this.editItem,
        },
        {
          category: "delete",
          icon: "mdi-delete",
          color: "error",
          text: "Delete",
          clickHandler: this.deleteItem,
        },
        {
          category: "destroy",
          icon: "mdi-delete-forever",
          color: "error",
          text: "Delete Forever",
          clickHandler: this.destroyItem,
        },
        {
          category: "restored",
          icon: "mdi-backup-restore",
          color: "primary",
          text: "Restore",
          clickHandler: this.restoreItem,
        },
      ],
    };
  },
  created() {
    if (this.actionButtons && this.actionButtons.length) {
      const actionButtons = this.actionButtons.map((button) => {
        if (button.clickHandler) {
          button.clickHandler = this[button.clickHandler];
        }
        return button;
      });

      this.defaultActionButtons = this.showDefaultActionButtons
        ? this.defaultActionButtons.concat(actionButtons)
        : [...actionButtons];
    }
  },
  props: {
    elevation: {
      type: String,
      default: () => "elevation-1",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    stateendpoint: {
      type: String,
      default: function () {
        return "";
      },
    },
    descending: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    sort: {
      type: String,
      default: function () {
        return "name";
      },
    },
    showDefaultActionButtons: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    actionButtons: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showSelected: {
      type: Boolean,
      default: function (){
        return false;
      }
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    pagination: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      data: function (state) {
        const stateData = this.stateendpoint
          .split(".")
          .reduce((p, c) => (p && p[c]) || null, state);

        this.total = stateData.total;
        return stateData.data;
      },
    }),
    dynamicColumns() {
      return this.columns.map((item) => {
        return {
          name: item.name,
          callback: this[item.callback],
        };
      });
    },
  },
  methods: {
    loadData(isPagingReset = false) {
      this.isOverlay = true;
      let paging = this.getPaging();

      this.$emit("loadData", { paging, isPagingReset });
    },
    handleActionButtonClick(data) {
      const actionButton = this.defaultActionButtons.find(
        (button) => button.category === data.category
      );

      actionButton.clickHandler(data.row);
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    destroyItem(item) {
      this.$emit("destroyItem", item);
    },
    restoreItem(item) {
      this.$emit("restoreItem", item);
    },
    switchHandler({ item, eventStatus }) {
      this.$emit("switchHandler", { item, eventStatus });
    },
    getPaging() {
      let { page, itemsPerPage, sortBy, sortDesc } = this.pagination;
      return {
        page,
        rowsPerPage: itemsPerPage,
        sortBy: sortBy[0],
        descending: sortDesc[0],
        s: "",
      };
    },
    selectedItemHandler() {
      this.$emit("selectedItemHandler", this.selected)
    },
    getMoneySign(item, column) {
      return "$" + item[column];
    },
    getPercentageSign(item, column) {
      return item[column]+"%";
    },
    getCountryName(item, column) {
      const country = countries.find((country) => country.code === item[column])
      if(country) {
        return country.name;
      }
      return "";
    }
  },
};
</script>

<style scoped></style>
