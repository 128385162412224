import _ from "lodash";

export default {
  methods: {
    loadData({ paging, isPagingReset }) {
      const self = this;
      Object.assign(self.currentPaging, {...paging})
      /*this.currentPaging = paging;*/
      this.isOverlay = true;
      if (isPagingReset) {
        self.currentPaging.page = 1;
      }
      self.currentPaging.s = this.searchTerm || "";
      if (!_.isEmpty(self.currentPaging.s)) {
        let queryLength = self.currentPaging.s.length;
        if (queryLength < self.searchLength || queryLength > self.searchLimit)
          return;
      }

      this.$store
        .dispatch(self.actions.load, { ...self.currentPaging })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          self.isOverlay = false;
        });
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.showPopup = true;
    },
    async deleteItem(item, confirm = false) {
      this.confirmEvent = this["deleteItem"];
      this.confirmTxt = "Are you sure to delete this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleDestroy(this.actions.remove, id);
    },
    async destroyItem(item, confirm = false) {
      this.confirmEvent = this["destroyItem"];
      this.confirmTxt = "Are you sure to destroy this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleDestroy(this.actions.destroy, id);
    },
    async restoreItem(item, confirm = false) {
      this.confirmEvent = this["restoreItem"];
      this.confirmTxt = "Are you sure to restore this item?";
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      const id = item.id;
      await this.handleDestroy(this.actions.restore, id);
    },
    async switchHandler({ item, eventStatus }) {
      await this.handleItem(this.actions.update, {
        ...item,
        isActive: eventStatus,
      });
    },
    async handleDestroy(actionHook, id) {
      this.$store
        .dispatch(actionHook, { id })
        .then(({ data: { message } }) => {
          this.setSnackBar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            text: data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.currentSelectedItem = null;
          this.loadData({ isPagingReset: false });
        });
    },
    openPopUp() {
      this.showPopup = true;
    },
    closePopupHandler() {
      this.showPopup = false;

      setTimeout(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    async saveItemHandler(item) {
      if (this.editedIndex > -1) {
        await this.handleItem(this.actions.update, item);
      } else {
        await this.handleItem(this.actions.store, item);
      }
    },
    async handleItem(actionHook, item) {
      this.$store
        .dispatch(actionHook, item)
        .then(({ data: { message } }) => {
          this.setSnackBar({ text: message, type: "info" });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            text: data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loadData({ isPagingReset: true });
        });
    },
    handleSearch(searchTerm) {
      if (typeof searchTerm === "undefined") return;
      this.searchTerm = searchTerm
        ? searchTerm.replace(/[\u200B-\u200D\uFEFF]/g, "")
        : "";
      this.loadData({ isPagingReset: true });
    },
    setSnackBar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", { text, type });
    },
  },
};
