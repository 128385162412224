import data from "../../../components/mixins/list/Data";
import props from "../../../components/mixins/list/Props";
import created from "../../../components/mixins/list/Created";
import mounted from "../../../components/mixins/list/Mounted";
import watch from "../../../components/mixins/list/Watch";
import computed from "../../../components/mixins/list/Computed";
import method from "../../../components/mixins/list/Methods";
export default {
  name: "ListMixin",
  mixins: [data, props, created, mounted, watch, computed, method],
};
