export default {
  data() {
    return {
      confirmEvent: "",
      confirmTxt: "",
      isOverlay: false,
      showPopup: false,
      showConfirm: false,
      currentSelectedItem: null,
      searchTerm: "",
      searchLength: 2,
      searchLimit: 250,
      currentPaging: {},
      editedItem: {},
      editedIndex: -1,
    };
  },
};
