export default {
  props: {
    showAddButton: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
};
